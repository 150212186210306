import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const NEST_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x04abEdA201850aC0124161F037Efd70c74ddC74C',
  [SupportedChainId.RINKEBY]: '0xE313F3f49B647fBEDDC5F2389Edb5c93CBf4EE25',
  [SupportedChainId.BSC]: '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
  [SupportedChainId.BSCTestnet]: '0x821edD79cc386E56FeC9DA5793b87a3A52373cdE'
}

export const NEST_OPEN_PLATFORM_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x638461F3Ae49CcC257ef49Fe76CCE5816A9234eF',
  [SupportedChainId.RINKEBY]: '0x638461F3Ae49CcC257ef49Fe76CCE5816A9234eF',
  [SupportedChainId.BSC]: '0x09CE0e021195BA2c1CDE62A8B187abf810951540',
  [SupportedChainId.BSCTestnet]: '0xF2f9E62f52389EF223f5Fa8b9926e95386935277'
}

export const NEST_REDEEMING_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xF48D58649dDb13E6e29e03059Ea518741169ceC8',
  [SupportedChainId.RINKEBY]: '0xeD859B5f5A2e19bC36C14096DC05Fe9192CeFa31'
}

export const NEST_NNINCOME_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x95557DE67444B556FE6ff8D7939316DA0Aa340B2',
  [SupportedChainId.RINKEBY]: '0x82307CbA43f05D632aB835AFfD30ED0073dC4bd9'
}

export const NEST_PRICE_FACADE: AddressMap = {
  [SupportedChainId.MAINNET]: '0xB5D2890c061c321A5B6A4a4254bb1522425BAF0A',
  [SupportedChainId.RINKEBY]: '0x40C3EB032f27fDa7AdcF1B753c75B84e27f26838'
}

export const NEST_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x04abeda201850ac0124161f037efd70c74ddc74c',
  [SupportedChainId.RINKEBY]: '0x3145AF0F18759D7587F22278d965Cdf7e19d6437'
}

export const NEST_LEDGER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x34B931C7e5Dc45dDc9098A1f588A0EA0dA45025D',
  [SupportedChainId.RINKEBY]: '0x005103e352f86e4C32a3CE4B684fe211eB123210'
}
